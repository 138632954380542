import {AbstractComponent, ComponentLoader} from "../../../../AbstractComponent";

declare let $:any;

export class ListElement extends AbstractComponent
{
    public static selector: string = 'list-element';

    public init() {
        super.init();
        let start = this.getComponentElement().attr('start');
        let array = start.split('.');
        let last = array[array.length-1];
        array.pop();
        let prefix = array.join('.');
        let li = this.getComponentElement().children('li');
        li.each((key, element)=>{
            let output = prefix+'.'+last;
            $(element).prepend(`<span>${output}</span>`);
            last = (+last + +1).toString();
        });
    }

    private increment(i)
    {
        let last = i.length-1;
        i[last] = i[last] < 9?i[last]+1 : 1;

        if(i[last] === 1)
        {
            let next = last - 1;
            while(next >= 0) {
                i[next] = i[next] < 9 ? i[next] + 1 : 1;

                if(i[next] != 1)
                {
                    return i;
                }

                next = next - 1;
            }
        }
        return i;
    }
}

new ComponentLoader(ListElement);
